import { RESPONSE_CODE } from "./constants";
import { errorConfig } from "./requestErrorConfig";
import { getUserInfoService } from "./service/user";
import ArmsRum from "@arms/rum-browser";
import { getBurialPointEnv } from "./utils";

const isProd = getBurialPointEnv() === "prod";
if (isProd) {
  ArmsRum.init({
    pid: "hmsa4pcrpj@22d71f413474479",
    endpoint: "https://hmsa4pcrpj-default-cn.rum.aliyuncs.com",
    // 设置环境信息，参考值：'prod' | 'gray' | 'pre' | 'daily' | 'local'
    env: getBurialPointEnv(),
    // 设置路由模式， 参考值：'history' | 'hash'
    spaMode: "history",
    collectors: {
      // 页面性能指标监听开关，默认开启
      perf: true,
      // WebVitals指标监听开关，默认开启
      webVitals: true,
      // Ajax监听开关，默认开启
      api: true,
      // 静态资源开关，默认开启
      staticResource: true,
      // JS错误监听开关，默认开启
      jsError: true,
      // 控制台错误监听开关，默认开启
      consoleError: true,
      // 用户行为监听开关，默认开启
      action: true,
    },
    // 链路追踪配置开关，默认关闭
    tracing: true,
    filters: {
      exception: [
        "CanceledError",
        "canceled",
        "Request failed with status code 401",
      ],
    },
  });
}

export async function getInitialState() {
  const rsp = await getUserInfoService();
  if (rsp.code === RESPONSE_CODE.SUCCESS) {
    if (isProd) {
      const nickName = rsp?.data?.user?.nickName;
      const userId = rsp?.data?.user?.userId;
      try {
        ArmsRum.setConfig("user", {
          name: nickName,
          tags: userId,
        });
      } catch (error) {
        console.error("ArmsRum.setConfig error", error);
      }
    }
    return {
      userInfo: rsp.data,
    };
  }
  return {
    userInfo: undefined,
  };
}

export const request = {
  ...errorConfig,
};
