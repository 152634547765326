export function getSecondLevelDomain() {
  const hostname = window.location.hostname;
  const urls = hostname.split(".");
  if (urls?.length <= 2) {
    return hostname;
  }
  urls.shift();
  return urls.join(".");
}

/** 获取个人端网址 */
export const getplatformUrl = () => {
  const host = window.location.host;
  if (host.startsWith("dev")) {
    return "https://devplatform.12times.com";
  } else if (host.startsWith("qas")) {
    return "https://qasplatform.12times.com";
  } else {
    return "https://platform.12times.com";
  }
};
/** 获取埋点环境 */
export const getBurialPointEnv = () => {
  const host = window.location.host;
  const prodHost = [
    "www.damselfish.cn",
    "platform.damselfish.cn",
    "damselfish.cn",
    "www.12times.com",
    "platform.12times.com",
    "12times.com",
  ];
  if (prodHost.includes(host)) {
    return "prod";
  } else {
    return "daily";
  }
};
