// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","id":"1"},"2":{"path":"/home","redirect":"/","parentId":"1","id":"2"},"3":{"path":"/","name":"damselfish-home","parentId":"1","id":"3"},"4":{"path":"advanced-customization","name":"damselfish-advanced-customization","parentId":"1","id":"4"},"5":{"path":"/about-us","name":"damselfish-about-us","parentId":"1","id":"5"},"6":{"path":"/login","name":"damselfish-login","parentId":"1","id":"6"},"7":{"path":"*","layout":false,"id":"7"}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "layout__index" */'@/layout/index.tsx')),
'2': React.lazy(() => import('./EmptyRoute')),
'3': React.lazy(() => import(/* webpackChunkName: "p__home__index" */'@/pages/home/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__advanced-customization__index" */'@/pages/advanced-customization/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__about-us__index" */'@/pages/about-us/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__login__index" */'@/pages/login/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
},
  };
}
