export const RESPONSE_CODE = {
  SUCCESS: 200,
};

export const OSS_IMAGE_URL = "https://actstatic.insnail.com/damselfish";

export const TOKEN = "damselfish-token";

export enum RoutePath {
  Home = "/",
  AdvancedCustomization = "/advanced-customization",
  // Pricing = "/pricing",
  // QuickStart = "/quick-start",
  AboutUs = "/about-us",
}
/** 官网埋点group */
export const BurialPointGroup = "12-times-official-website";

export enum BurialPointEventType {
  /** 浏览页面 */
  Browse = "Browse",
  /** 点击事件 */
  Click = "Click",
}

/**
 * 官网页面滚动到具体位置埋点
 * 最后的数字越大，越靠下面
 */
export enum BurialPointPageContentType {
  HomePageChooseUs2 = "首页2-选择12times",
  HomePageProductFeature3 = "首页3-产品特点",
  HomePageEmployee4 = "首页4-007员工",
  HomePageNewWays5 = "首页5-新方式",
  HomePageContrast6 = "首页6-对比",
  HomePageWorkEffect7 = "首页7-工作效果",
  HomePageOutstanding8 = "首页8-卓越",
  HomePagePartnerTheory9 = "首页9-合作伙伴说",
}

/** 官网点击埋点 */
export enum BurialPointClickEventType {
  OpenContactModal = "打开客服弹窗",

  HomePageHeaderAnimationTryNow = "首页-顶部动画-立即试用",
  HomePageNewWaysTryNow = "首页-新方式-立即试用",
  HomePageContrastTryNow = "首页-对比-立即试用",
}
