import { request } from "@umijs/max";
enum GrantEnum {
  DamselfishMain = "damselfish_main",
  DamselfishRamPassword = "damselfish_ram_password",
}

export enum AccountTypeEnum {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

export type User = {
  account: string;
  avatar: string;
  clientId: string;
  deptId: string;
  detail: {
    userType: string;
  };
  nickName: string;
  oauthId: string;
  postId: string;
  roleId: string;
  roleName: string;
  tenantId: string;
  userId: string;
  userName: string;
  access_token?: string;
};
/** 发送验证码 */
export function sendCaptchaService(account: string) {
  return request<API.BaseResponse<null>>(
    "/api/damselfish-central-backend/manage/captcha",
    {
      method: "POST",
      data: { account: account?.trim() },
    }
  );
}

/** 根据验证码获取用户 */
export function loginByCaptchaService(data: {
  account: string;
  captcha: string;
}) {
  return request<API.BaseResponse<User[]>>(
    "/api/damselfish-central-backend/manage/loginByCaptcha",
    {
      method: "POST",
      data: {
        ...data,
        account: data.account?.trim(),
      },
    }
  );
}

/** 根据密码获取用户 */
export function loginByPasswordService(data: {
  account: string;
  password: string;
}) {
  return request<API.BaseResponse<User[]>>(
    "/api/damselfish-central-backend/manage/loginByPassword",
    {
      method: "POST",
      data: {
        ...data,
        account: data.account?.trim(),
      },
    }
  );
}

export type queryAccountExistParams = {
  account: string;
  type: AccountTypeEnum;
  captcha: string;
};

/** 手机-邮箱是否存在注册过的账号 */
export function queryAccountExistService(data: queryAccountExistParams) {
  return request<API.BaseResponse<boolean>>(
    "/api/damselfish-central-backend/manage/exists",
    {
      method: "POST",
      data: {
        ...data,
        account: data.account?.trim(),
      },
    }
  );
}
/** 注册 */
export function registerService(data: {
  account: string;
  type: AccountTypeEnum;
}) {
  return request<API.BaseResponse<User>>(
    "/api/damselfish-central-backend/manage/register",
    {
      method: "POST",
      data: {
        ...data,
        account: data.account?.trim(),
      },
    }
  );
}

export interface LoginParams {
  userId: string;
}
/** userId登录 */
export function userIdLoginService(params: LoginParams) {
  return request<User & { error: string; error_description: string }>(
    "/api/blade-auth/oauth/token",
    {
      method: "POST",
      params: {
        ...params,
        grant_type: GrantEnum.DamselfishMain,
      },
      headers: {
        Authorization:
          "Basic ZGFtc2VsZmlzaDpkYjFmOTAwOGQ1YzY0MDQ2YjY4NjgyMjE5Y2Y0NmMzMg==",
      },
    }
  );
}
export interface RamLoginParams {
  account: string;
  password: string;
}
/** RAM登录 */
export function ramLoginService(params: RamLoginParams) {
  return request<User & { error: string; error_description: string }>(
    "/api/blade-auth/oauth/token",
    {
      method: "POST",
      params: {
        ...params,
        account: params.account?.trim(),
        grant_type: GrantEnum.DamselfishRamPassword,
      },
      headers: {
        Authorization:
          "Basic ZGFtc2VsZmlzaDpkYjFmOTAwOGQ1YzY0MDQ2YjY4NjgyMjE5Y2Y0NmMzMg==",
      },
    }
  );
}
export enum UserType {
  MAIN = "MAIN",
  RAM = "RAM",
}
export enum TenantType {
  /** 试用版 */
  PERSON = "PERSON",
  /** 正式版 */
  ENTERPRISE = "ENTERPRISE",
}

export interface ITenant {
  contactNumber: string;
  linkMan: string;
  tenantId: string;
  tenantName: string;
  tenantType: TenantType;
}

export interface IUser {
  account: string;
  clientId: string;
  deptId: string;
  detail: Record<string, unknown>;
  nickName: string;
  oauthId: string;
  postId: string;
  roleId: string;
  roleName: string;
  tenantId: string;
  userId: string;
  userName: string;
  avatar: string;
}
export interface IUserInfo {
  enableEmail: boolean;
  enablePhone: boolean;
  role: string;
  tenant: ITenant;
  user: IUser;
  userType: UserType;
}

export function getUserInfoService() {
  return request<API.BaseResponse<IUserInfo>>(
    "/api/damselfish-central-backend/manage/info"
  );
}

export interface IUpdateUserDto {
  nickName: string;
  email: string;
  enableEmail: boolean;
  enablePhone: boolean;
  phone: string;
  password: string;
}
/** 修改用户信息，不传则不改 */
export function updateUserInfoService(data: Partial<IUpdateUserDto>) {
  return request<API.BaseResponse>(
    "/api/damselfish-central-backend/manage/updateAccountInfo",
    {
      method: "PUT",
      data: {
        ...data,
        nickname: data.nickName?.trim(),
      },
    }
  );
}
